import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { v4 as uuidv4 } from "uuid"; // Import uuid for generating session IDs and user IDs
import "./App.css";

function App() {
  const [chatHistory, setChatHistory] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null); // State to store session ID
  const [userId, setUserId] = useState(null); // State to store user ID

  const endOfChatRef = useRef(null);

  // Automatically scroll to the bottom when new messages are added
  useEffect(() => {
    if (endOfChatRef.current) {
      endOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  // Generate a session ID and user ID when the component mounts
  useEffect(() => {
    const newSessionId = uuidv4(); // Generate a unique session ID
    const newUserId = uuidv4(); // Generate a unique user ID
    setSessionId(newSessionId); // Store the session ID in state
    setUserId(newUserId); // Store the user ID in state
    console.log("Generated session ID:", newSessionId); // For debugging
    console.log("Generated user ID:", newUserId); // For debugging
  }, []);

  useEffect(() => {
    adjustTextAreaHeight();
  }, [question, chatHistory]);

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (isMobile()) {
        return;
      } else {
        if (event.shiftKey) {
          return;
        } else {
          event.preventDefault();
          if (!loading && question.trim()) {
            callApi();
          }
        }
      }
    }
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const callApi = async () => {
    if (question.trim() === "") return;

    setLoading(true);
    setError(null);

    const timestamp = getCurrentTimestamp();
    const userMessage = { sender: "user", message: question, timestamp };

    setChatHistory((prevHistory) => [
      ...prevHistory,
      userMessage,
      { sender: "AI", message: "Typing...", timestamp },
    ]);

    setQuestion("");

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/query`; // Backend endpoint for query

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question, sessionId, userId }), // Send question, sessionId, and userId
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const result = await response.json();
      const aiMessage = { sender: "AI", message: result.response, timestamp };

      setChatHistory((prevHistory) => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1] = aiMessage;
        return updatedHistory;
      });
    } catch (error) {
      console.error("Error calling API:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const adjustTextAreaHeight = () => {
    const textarea = document.querySelector(".footer-textarea");
    if (textarea) {
      textarea.style.height = "auto";
      const maxHeight = 100;

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = "scroll";
      } else {
        textarea.style.height = `${textarea.scrollHeight}px`;
        textarea.style.overflowY = "hidden";
      }
    }
  };

  // Function to replace \n with <br /> for line breaks
  const formatMessage = (message) => {
    return message.replace(/\n/g, "  \n");
  };

  return (
    <div className="chat-container">
      <header className="chat-header">
        <h1>ReloGPT</h1>

        <a
          href="https://www.packimpex.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/Packimpex_Claim_Colour.png" alt="Pack Impex" />
        </a>
      </header>

      <main className="chat-main">
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message-container ${chat.sender}`}>
            {chat.sender === "AI" && (
              <div className="profile">
                <img src="/Roundicon.png" alt="AI" />
                <div className="timestamp">{chat.timestamp}</div>
              </div>
            )}
            <div className={`chat-message ${chat.sender}`}>
              {chat.sender === "AI" && <span className="ai-message-arrow" />}
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ node, ...props }) => (
                    <div className="responsive-table">
                      <table {...props} />
                    </div>
                  ),
                  a: ({ node, ...props }) => {
                    const href = props.href.startsWith("http")
                      ? props.href
                      : `https://${props.href}`;
                    return (
                      <a
                        {...props}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.children}
                      </a>
                    );
                  },
                }}
              >
                {formatMessage(chat.message)}
              </ReactMarkdown>
            </div>
            {chat.sender === "user" && (
              <div className="profile">
                <img src="/Userprofile.png" alt="User" />
                <div className="timestamp">{chat.timestamp}</div>
              </div>
            )}
          </div>
        ))}
        <div ref={endOfChatRef} />
        {error && <div className="error-message">{error}</div>}
      </main>
      <footer className="chat-footer">
        <div className="footer-container">
          <textarea
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
              adjustTextAreaHeight();
            }}
            onKeyPress={handleKeyPress}
            placeholder="Got a question? Packimpex AI is here to help!"
            className="footer-textarea"
            aria-label="Type your message"
          />
          <button
            onClick={callApi}
            disabled={loading || question.trim() === ""}
            className="footer-button"
            aria-label="Send message"
          >
            <ArrowUpwardIcon />
          </button>
        </div>
        <div className="disclaimer">
          <small>
            This system is powered by AI and can make mistakes. Do not enter
            personal information.
          </small>
        </div>
      </footer>
    </div>
  );
}

export default App;
